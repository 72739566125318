import React from 'react'

function AlternateMobileInput() {
  return (
    <>
    
    <div class="register-form-control ">
        <label class="register-label">
        Alternate Mobile No.</label>
            <input type="text"
             name="alternatemobile"
              class="register-input "
               placeholder="Enter mobile number" value="" /> 
      </div>
      
    </>
  )
}

export default AlternateMobileInput
