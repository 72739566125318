import React from 'react'

function PhoneInput() {
  return (
    <>
       <div class="register-form-control ">
        <label class="register-label">
        Mobile Number</label>
            <input type="text"
             name="firstName"
              class="register-input "
               placeholder="Enter mobile number" value="" /> 
      </div>
    </>
  )
}

export default PhoneInput
