import React from 'react'

function CorporateDashboard() {
  return (
    <div>
     dashboard 
    </div>
  )
}

export default CorporateDashboard
