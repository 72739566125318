
function PersonalEmailInput() {
  return (
    <>
      <div class="register-form-control ">
        <label class="register-label">
        Personal  Email ID</label>
            <input type="text"
             name="email"
              class="register-input "
               placeholder="Enter Email ID" value="" /> 
      </div>
    </>
  )
}

export default PersonalEmailInput
