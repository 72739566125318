import React from 'react'

function AlternateEmailInput() {
  return (
    <>
         <div class="register-form-control ">
        <label class="register-label">
        Alternate Email ID</label>
            <input type="text"
             name="alternateemail"
              class="register-input "
               placeholder="Enter Email" value="" /> 
      </div>
    </>
  )
}

export default AlternateEmailInput
