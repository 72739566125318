import React from 'react'

function CandidateRegisterForm() {
  return (
    <div>
      
    </div>
  )
}

export default CandidateRegisterForm
