import React from 'react'

function DesignationInput() {
  return (
    <>
      <div class="register-form-control ">
        <label class="register-label">Designation</label>
        <input type="text" name="designation" class="register-input "
         placeholder="Enter designation" value="" />

     </div>
    </>
  )
}

export default DesignationInput

