import React from 'react'

function PasswordInput() {
  return (
    <>
        <div class="register-form-control ">
        <label class="register-label">Enter Password</label>
        <input type="password" name="designation" class="register-input "
         placeholder="Enter Password" value="" />

     </div>
    </>
  )
}

export default PasswordInput
